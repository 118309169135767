export default {
    async getAll(clientId) {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientId}/invoices`);
            return data;
        } catch (error) {
            console.log('🚀 ~ file: invoices.api.js ~ getAll ~ error', error);
            throw error;
        }
    },

    async getDetails(clientId, invoiceId) {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientId}/invoices/${invoiceId}`);
            return data;
        } catch (error) {
            console.log('🚀 ~ file: invoices.api.js ~ getDetails ~ error', error);
            throw error;
        }
    },

    async print(clientId, invoiceId) {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientId}/invoices/${invoiceId}/print`);
            return data;
        } catch (error) {
            console.log('🚀 ~ file: invoices.api.js ~ print ~ error', error);
            throw error;
        }
    },
};
