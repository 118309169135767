<template>
    <div class="flex flex-col flex-1">
        <div v-if="invoiceData.client_data" class="card">
            <header class="flex items-center justify-between h-10 border-b border-solid border-gray-200 pb-4 mb-4">
                <el-button type="primary" @click="$router.push({ name: 'client_invoices'})">
                    {{ $t('common.go_back') }}
                </el-button>
                <div>
                    <el-dropdown trigger="click" class="mr-2" placement="bottom">
                        <el-button type="primary" :disabled="!userCan('update invoices')" :loading="$wait.is('updating')">
                            {{ $t('economy.change_status') }}<i class="el-icon-arrow-down el-icon--right" />
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :disabled="!['draft'].includes(invoiceData.status)" @click.native="changeStatus('confirmed')">
                                {{ $t('economy.mark_as_closed') }}
                            </el-dropdown-item>
                            <el-dropdown-item :disabled="!['confirmed'].includes(invoiceData.status)" @click.native="changeStatus('sent')">
                                {{ $t('economy.mark_as_sent') }}
                            </el-dropdown-item>
                            <el-dropdown-item :disabled="!['sent', 'unpaid'].includes(invoiceData.status)" @click.native="changeStatus('paid')">
                                {{ $t('economy.mask_as_paid') }}
                            </el-dropdown-item>
                            <el-dropdown-item :disabled="!['paid'].includes(invoiceData.status) || invoiceData.client_data.type === 'company'" @click.native="changeStatus('rut_sent')">
                                {{ $t('economy.mark_as_rut_sent') }}
                            </el-dropdown-item>
                            <el-dropdown-item :disabled="!['paid', 'rut_sent'].includes(invoiceData.status)" @click.native="changeStatus('completed')">
                                {{ $t('economy.mark_as_completed') }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.send_by_email')" placement="bottom">
                        <el-button type="primary" @click="sendEmail">
                            <fa-icon :icon="['fas', 'envelope']" />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.print')" placement="bottom">
                        <el-button type="primary" @click="handlePrintDownload(0)">
                            <fa-icon :icon="['fas', 'print']" />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.download')" placement="bottom">
                        <el-button type="primary" @click="handlePrintDownload(1)">
                            <fa-icon :icon="['fas', 'download']" />
                        </el-button>
                    </el-tooltip>
                </div>
            </header>

            <div class="relative flex justify-between text-xs">
                <p
                    class="flex h-6 items-center z-10 bg-white px-3 border rounded-full"
                    :class="{
                        'bg-green-500 text-white': invoiceData.status === 'draft',
                        'bg-gray-200 text-gray-500': ['confirmed', 'sent', 'unpaid', 'paid', 'rut_sent', 'completed'].includes(invoiceData.status)
                    }"
                >
                    Utkast
                </p>
                <p
                    class="flex h-6 items-center z-10 bg-white px-3 border rounded-full"
                    :class="{
                        'bg-green-500 text-white': invoiceData.status === 'confirmed',
                        'bg-gray-200 text-gray-500': ['sent', 'unpaid', 'paid', 'rut_sent', 'completed'].includes(invoiceData.status)
                    }"
                >
                    Bekräftad
                </p>
                <p
                    class="flex h-6 items-center z-10 bg-white px-3 border rounded-full"
                    :class="{
                        'bg-green-500 text-white': invoiceData.status === 'sent',
                        'bg-gray-200 text-gray-500': ['unpaid', 'paid', 'rut_sent', 'completed'].includes(invoiceData.status)
                    }"
                >
                    Skickat
                </p>
                <p
                    class="flex h-6 items-center z-10 bg-white px-3 border rounded-full"
                    :class="{
                        'bg-green-500 text-white': invoiceData.status === 'unpaid',
                        'bg-gray-200 text-gray-500': ['paid', 'rut_sent', 'completed'].includes(invoiceData.status)
                    }"
                >
                    Obetald
                </p>
                <p
                    class="flex h-6 items-center z-10 bg-white px-3 border rounded-full"
                    :class="{
                        'bg-green-500 text-white': invoiceData.status === 'paid',
                        'bg-gray-200 text-gray-500': ['rut_sent', 'completed'].includes(invoiceData.status)
                    }"
                >
                    Betald
                </p>
                <p
                    v-if="invoiceData.client_data.type === 'private'"
                    class="flex h-6 items-center z-10 bg-white px-3 border rounded-full"
                    :class="{
                        'bg-green-500 text-white': invoiceData.status === 'rut_sent',
                        'bg-gray-200 text-gray-500': ['completed'].includes(invoiceData.status)
                    }"
                >
                    RUT skickat
                </p>
                <p
                    class="flex h-6 items-center z-10 bg-white px-3 border rounded-full"
                    :class="{
                        'bg-green-500 text-white': invoiceData.status === 'completed'
                    }"
                >
                    Klart
                </p>
                <div class="absolute border-b w-full top-[11px]" />
            </div>
        </div>

        <!-- <pre>{{invoiceData}}</pre> -->

        <div class="card">
            <ClientUserInformation :invoiceData="invoiceData" />

            <Content :invoiceData="invoiceData" />

            <Footer :invoiceData="invoiceData" />

            <InitialLoader v-if="$wait.is('loading.initial_*')" />
        </div>
    </div>
</template>
<script>
import Cookies from 'js-cookie';
import Api from './invoices.api';

export default {
    components: {
        ClientUserInformation: () => import(/* webpackChunkName: "ClientInvoicesDetailsUserClient" */ './InvoicesDetailsUserClient'),
        Content:               () => import(/* webpackChunkName: "ClientInvoicesDetailsContent" */ './InvoicesDetailsContent'),
        Footer:                () => import(/* webpackChunkName: "ClientInvoicesDetailsFooter" */ './InvoicesDetailsFooter'),
    },

    props: {
        clientId: {
            type:    Number,
            default: null,
        },
    },

    data() {
        return {
            noDataInformation:     false,
            invoiceData:           {},
            invoiceId:             this.$route.params.invoiceId,
            sendEmailModalVisible: false,
        };
    },

    beforeCreate() {
        this.$wait.start('loading.initial_invoice');
    },

    created() {
        this.getDetails();
    },

    methods: {
        async getDetails() {
            this.$wait.start('loading.initial_invoice');
            try {
                this.invoiceData = await Api.getDetails(this.clientId, this.invoiceId);
                this.noDataInformation = !!this.invoicesData?.uuid;
            } finally {
                this.$wait.end('loading.initial_invoice');
            }
        },

        async handlePrintDownload(download) {
            const apiToken = Cookies.get('api_token');
            window.open(
                `${process.env.VUE_APP_CORE_API_URL}/clients/clients/${this.clientId}/invoices/${this.invoiceId}/print?api_token=${apiToken}&download=${download}`,
                download ? '_self' : '',
            );
        },

        sendEmail() {
            this.sendEmailModalVisible = true;
        },
    },
};
</script>
